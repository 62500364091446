import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import FOAM_API from "../../../../utils/api";
import { getToken } from "../../../../utils/auth";

const initialState = {
  data: [],
  loading: false,
  error: null
};

export const fetchFoamProcessingScheduleByDate = createAsyncThunk(
  "foamPour/fetchFoamProcessingScheduleByDate",
  async (requestData) => {
    const token = await getToken();
    if (!token) {
      throw new Error("Authentication token not found in Azure.");
    }
    const response = await fetch(
      FOAM_API + "/fetch-foam-processing-schedule-by-date",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(requestData) // Make sure to pass the required data here
      }
    );
    const data = await response.json();
    return data;
  }
);
export const postPouredFoamSku = createAsyncThunk(
  "foamPour/postPouredFoamSku",
  async (requestData) => {
    const token = await getToken();
    if (!token) {
      throw new Error("Authentication token not found in Azure.");
    }
    const response = await fetch(FOAM_API + "/post-poured-foam-sku", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(requestData) // Make sure to pass the required data here
    });
    const data = await response.json();
    return data;
  }
);

export const fetchLongBunSerialByDateRange = createAsyncThunk(
  "foamPour/fetchLongBunSerialByDateRange",
  async (requestData, { rejectWithValue }) => {
    try {
      const token = await getToken();
      if (!token) {
        throw new Error("Authentication token not found in Azure.");
      }

      const response = await fetch(
        `${FOAM_API}/fetch-long-bun-serial-by-date-range`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            startDate: requestData.startDate,
            endDate: requestData.endDate,
          }),
        }
      );

      if (!response.ok) {
        // Return a rejected value if the response status is not OK
        return rejectWithValue(`Failed to fetch: ${response.statusText}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      // Use `rejectWithValue` to pass an error message to Redux if the call fails
      return rejectWithValue(error.message);
    }
  }
);


export const foamSlice = createSlice({
  name: "foam",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFoamProcessingScheduleByDate.pending, (state) => {
        state.loading = true;
        state.fetchFoamProcessingScheduleByDateError = null;
      })
      .addCase(fetchFoamProcessingScheduleByDate.fulfilled, (state, action) => {
        state.loading = false;
        state.fetchFoamProcessingScheduleByDateData = action.payload;
      })
      .addCase(fetchFoamProcessingScheduleByDate.rejected, (state, action) => {
        state.loading = false;
        state.fetchFoamProcessingScheduleByDateError = action.error.message;
      })

      // Create Long Bun Schedule
      .addCase(postPouredFoamSku.pending, (state) => {
        state.loading = true;
        state.postPouredFoamSkuError = null;
      })
      .addCase(postPouredFoamSku.fulfilled, (state, action) => {
        state.loading = false;
        state.postPouredFoamSkuData = action.payload;
      })
      .addCase(postPouredFoamSku.rejected, (state, action) => {
        state.loading = false;
        state.postPouredFoamSkuError = action.error.message;
      });
  }
});
