import React, { useState , useEffect, forwardRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField, 
  Snackbar,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useDispatch } from "react-redux";
import { fetchLongBunSerialByDateRange } from "./actions/foamPourActions";
import { Parser } from "@json2csv/plainjs";
import moment from "moment";
import MuiAlert from "@mui/material/Alert";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const DateRangeDialogWithExport = (props) => {
  const dispatch = useDispatch();
  const [openDateRangePicker, setOpenDateRangePicker] = useState(
    props.openRangePicker
  );
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [snackBarStatus, setSnackBarStatus] = useState({
    open: false,
    message: "",
    severity: ""
  });

  useEffect(() => {
    setOpenDateRangePicker(  props.openRangePicker)
  }, [props]);

  const exportToCSV = async (data) => {

    const fields = [
      {
        label: "SKU",
        value: row => `${row.foam_pour_schedule[0].foam_sku[0].sku}`,
      },
      { label: "Serial Number", value: row => `${row.serial_number}'` },
      {
        label: "Length",
        value: row => `${row.long_bun_length}`
      },
      {
        label: "Width",
        value: row => `${row.long_bun_width}`
      },
      {
        label: "Height",
        value: row =>`${row.long_bun_height}`
      },
      {
        label: "Pour Date",
        value: row => moment(row.pour_date).format("MM-DD-YYYY")
      },
      {
        label: "BD-FT",
        value: row =>
          Number(
            row.long_bun_length * row.long_bun_width * row.long_bun_height / 144
          ).toLocaleString(undefined, {
            maximumFractionDigits: 0
          })
      }
    ];

    const json2csvParser = new Parser({ fields });
    const csv = json2csvParser.parse(data);

    // format todays datetime for file
    const today = moment().format("YYYY-MM-DD_HH-mm-ss");
    const filename = `foam_process_report_${today}.csv`;

    // Download the CSV
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    setSnackBarStatus((prevState) => ({
        ...prevState,
        open: true,
        message: "Foam Pour Processing Report Successfully Exported",
        severity: "success"
      }));
  };

  const handleSubmit = async () => {
    if (!startDate || !endDate) {
  
      setSnackBarStatus((prevState) => ({
        ...prevState,
        open: true,
        message: "Please select both start and end dates",
        severity: "error"
      }));
      return;
    }

    // Close the dialog
    setOpenDateRangePicker(false);
    props.close()

    // Fetch data based on the selected date range
    try {
      const actionResult = await dispatch(
        fetchLongBunSerialByDateRange({
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString()
        })
      ).unwrap();

      exportToCSV(actionResult)
 
    } catch (rejectedValueOrSerializedError) {
      // Handle the error
      console.error(
        "Failed to fetch foam processing schedule:",
        rejectedValueOrSerializedError
      );
    }
  };

  const handleCancel = () => {
    setOpenDateRangePicker(false);
    props.close() 
    setSnackBarStatus((prevState) => ({
        ...prevState,
        open: true,
        message: "Export CSV Canceled",
        severity: "info"
      }));
  };

   /* handles the snack bar close */
   const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarStatus((prevState) => ({
      ...prevState,
      open: false
    }));
  };

  return (
    <div>
        <Snackbar
        open={snackBarStatus.open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Alert
          className="alert-message"
          onClose={handleClose}
          severity={snackBarStatus.severity}
          sx={{ width: "100%" }}
        >
          {snackBarStatus.message}
        </Alert>
      </Snackbar>
      <Dialog open={openDateRangePicker} onClose={handleCancel}>
        <DialogTitle>Select Date Range</DialogTitle>
        <DialogContent sx={{ minHeight:"100px", justifyContent:"space-between"}}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              sx={{my:4}}
              value={startDate}
              onChange={newValue => setStartDate(newValue)}
              renderInput={params =>
                <TextField {...params} fullWidth margin="normal" />}
            />
            <DatePicker
              label="End Date"
              value={endDate}
              minDate={startDate} 
              sx={{my:4}}
              onChange={newValue => setEndDate(newValue)}
              renderInput={params =>
                <TextField {...params} fullWidth margin="normal" />}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions sx={{justifyContent: "space-between", px:3}}>
          <Button className="dialog-button" onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleSubmit} className="dialog-button ">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DateRangeDialogWithExport;
